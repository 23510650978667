// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//  const base_url = 'https://raterequest-qa.basf.com/api/';
 //const base_url = 'https://raterequest-dev.basf.com/api/';
//const base_url = '/'

 const base_url = 'https://raterequest-dev.basf.com/api/';
 
 const app_url = 'https://raterequest-dev.basf.com/';
//const base_url = 'http://localhost:44369/';
//const app_url = 'http://localhost:44369/';
export const environment = {
  production: false,
  getRoles: `${base_url}admin/roles/requestedby/{requestedby}`,
  getUsers: `${base_url}admin/users/all/requestedby/{requestedby}`,
  postUser: `${base_url}admin/users`,
  deleteUser: `${base_url}admin/users`,

  getCleaningCategories: `${base_url}admin/cleaningcategory/all/requestedby/{requestedby}`,
  addCleaningCategory: `${base_url}admin/cleaningcategory/save`,
  updateCleaningCategory: `${base_url}admin/cleaningcategory/update`,
  deleteCleaningCategory: `${base_url}admin/cleaningcategory/delete/{mfgid}/requestedby/{requestedby}`,

  getVendors: `${base_url}admin/vendors/all/requestedby/{requestedby}`,
  addVendor: `${base_url}admin/vendors/create`,
  updateVendor: `${base_url}admin/vendors/update`,
  deleteVendor: `${base_url}vendor/deletevendor/`,
  getDropDownData: `${base_url}request/getLoadData`,
  getRegionsData: `${base_url}admin/getRegionsData/`,
  getModesbasedonRegion: `${base_url}admin/GetModesbasedonRegion/`,
  getLanguagesbasedonRegion: `${base_url}admin/getLanguagesbasedonRegion/`,

  updateBRRForm: `${base_url}request/UpdateRequestDetails`,
  emailExcel: `${base_url}BulkRequestExporttoExcel/ExporttoExcel/`,

  getRequestInfo: `${base_url}Request/get/Request-Response-Details/`,
  submitVendors: `${base_url}VendorResponse/carrierresponsebids`,
  notifyVendors: `${base_url}VendorResponse/notifybidvendor`,
  submitBidVendor: `${base_url}VendorResponse/submitvendorbidresponse`,
  getAllVendorUsers:`${base_url}vendors/GetVendorUsers/{transportType}`,

  // login_dev: `https://raterequest-dev.basf.com/login`,
  // login_qa: `https://raterequest-dev.basf.com/login`,
  // login: `https://raterequest-dev.basf.com/login`,
  // getSAMLURL: `https://raterequest-dev.basf.com/api/saml/GetSamlURL`,
  // getSAMLServiceURL: `https://raterequest-dev.basf.com/api/Getsaml`,
  login_dev: `${app_url}login`,
  login_qa: `${app_url}login`,
  login: `${app_url}login`,
  getSAMLURL: `${app_url}saml/GetSamlURL`,
  getSAMLExternalURL: `${app_url}saml/GetSamlExternalURL`,
  getSAMLServiceURL: `${app_url}Getsaml`,
  
  // getSAMLURL: `https://raterequest-dev.basf.com/api/saml/GetSamlURL`,
  // getSAMLServiceURL: `https://raterequest-dev.basf.com/api/Getsaml`,
  //  getSAMLURL: `http://localhost:44369/saml/GetSamlURL`,
  //  getSAMLServiceURL: `http://localhost:44369/Getsaml`,
  getLoggedUserData: `${base_url}RoleAndPermissions/GetRoleAndPermissions`,
  getLoggedUserDatabyId: `${base_url}RoleAndPermissions/GetRoleAndPermissionsbyuserId`,

  getArticleList: `${base_url}request/GetArticleNumbers`,
  
  getArticleProductList: `${base_url}admin/article/all/requestedby/{requestedby}`,
  submitArticleProuctList: `${base_url}admin/article/create`,

  getCountries: `${base_url}request/GetCountyList`,
  getUOMParams: `${base_url}request/GetUOMParameters/`,
  vendorCurrency: `${base_url}VendorResponse/CurrencyType`,
  decryptUserDetails: `${base_url}users/decryptUserdetails`,
  getQAUserDetails: `${base_url}users/AuthenticateUser/`,
  getSiteComptitiveList :`${base_url}admin/siecomp/all/requestedby/{requestedby}`,
  submitSiteComptivelist: `${base_url}admin/sitecom/save`,
  getRailCarTypeList:`${base_url}admin/railcartype/all/requestedby/{requestedby}`,
  submitRailCartype: `${base_url}admin/railcartype/save`,

  getTempRequirement:`${base_url}admin/TempRequirement/all/requestedby/{requestedby}`,
  submitTempRequirement: `${base_url}admin/TempRequirement/save`,

  
  getDeliveryType:`${base_url}admin/DeliveryType/all/requestedby/{requestedby}`,
  submitDeliveryType: `${base_url}admin/DeliveryType/save`,




//Deleted
// getBulkTransportMasterAPI: `${base_url}Master/BulkTransport`,
// getRequests: `${base_url}request/get/all/`,

//New /Updated
  getBulkTransportMasterAPI: `${base_url}Master/TransportType/IsExternal/{isExternal}/UserId/{userId}`,
  getRequestMasterAPI: `${base_url}Master/Request`,
  getRequestArticleMaster:`${base_url}Master/Request/Articles`,

  getArticleDetails: `${base_url}Requests/ArticleInfo/`,
  getRequests: `${base_url}Requests/Mode/{mode}/IsExternal/{isExternal}/UserId/{userId}`,

  postBRRForm: `${base_url}Requests/Submit`,
  getRequestDetails: `${base_url}Requests/{0}/transportType/{1}`,

  getVendorMasterList:`${base_url}/Master/Users/{userId}/Request/TransportType/{transportType}/Vendors`,
  getVendorCurrencyMasterList:`${base_url}/Master/Request/Vendor/Currency`,
  getVendorResponseDetails:`${base_url}Request/Vendors/Response/{0}/TranportType/{1}/IsExternal/{isExternal}/UserId/{userId}`,
  getNumberofCars:`${base_url}Requests/RailRate/NumberofCars/{0}/{1}/{2}`,
  exportRequestList: `${base_url}Requests/ExportExcel/{0}/IsExternal/{1}/UserId/{2}`,

  getRequestPlantCodesMaster:`${base_url}Master/Request/PlantCodes/{transportType}/IsOrigin/{isOrigin}/PlantCode/{plantCode}`,
  getPlantCodeByMasterId:`${base_url}Master/Request/PlantCodes/{transportType}/PlantCodeId/{plantCodeId}`


};
