import { RequestFormData } from "./request-common.data";

export class LiquidTruckRequestFormData extends RequestFormData {
    articleNumber: string="";
    cleaningCost: string="";
    freighClass: string="";
    internModel: string="";
    mfg: string="";
    equipmentTypeId:number=0;
    naCountry :string="";
    typeOfShipment : string="";
    monthlyKms : number=0;

    //Service provider
    days: number=0;
    dropTrail: string="";
    monthlyVolume: number=0;
    monthlyVolumeUnitId: number=0;
    centerRear: string="";
    centerRearUnload: string="";
    foodGradeWash: string="";
    groundVapourDelivery: string="";
    hazardous: string="";
    kosher: string="";
    loadingHours:  string="";
    materialTopLoad: string="";
    nitrogenBlanket: string="";
    priorProductRestriction: string="";
    priorProdDetailsRestrictions: string = "";
    tempRange: string="";
    tempRequirementId: number=0;
    unloadingHour:  string="";
    weekendDelivery: string="";
    cleaningProcedures:string="";
    mandadoryDocsInsideTruck:string="";
    mandadoryDocsInsideTruckId:number=0;
   override mandadoryDocsInsideTruckIdSelected:any=[];

    // FISPQRequirement:string="";
    FISPQFileName:string="";
    FISPQAttachment:any;
    FISPQFileExtension:string="";

    averageProduct:string="";
    averageProductId:number=0;

    pumpId:number=0;
    chassisId:number=0;
    couplings:string="";
    loadingPointId:number=0;
    gmp:string="";
    fami:string="";
    isopa:string="";
    halalWash:string="";
    unloadingHose:string="";
    onboardCompressor:string="";
    others:string="";

    isoTankRentalRequirementId:number=0;
    isoTankRentalPeriod:string="";
    tankTypeId:number=0;
    specificRemarkForTankType:string="";
    topBottomLoad:string="";
    topBottomUnload:string="";
    cleaningService:string="";
    cleaningFrequency:string="";
    heatingService:string="";
    heatingTemperature:string="";
    priorProductRestrictionDetails:string="";
    tariffZone:string="";
    dangerousGoodsClassId:number=0;
    otherAdditionalServiceRequirement:string="";

    totalWeightShipment: number=0;
    totalWeightShipmentUid:number=0;

    multipleDestination:MultipleDestination[]=[];

    isSpecialRequirement:string="";
    specialRequirementFileName:string="";
    specialRequirementAttachment:any;
    specialRequirementFileExtension:string="";



}
export class MultipleDestination{
    multipleShippingId:number=0;
    multipleDestinationPlantId:number=0;
    multipleDestinationPlantCode:string="";
    multipleDestinationCity: string="";
    multipleDestinationCountry: string="";
    multipleDestinationConsigneeName: string="";
    multipleDestinationState: string="";
    multipleDestinationZipCode: string="";
    selectedDestinationPlantCode:any;
    destinationPlantMode:boolean=false;
}